import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-flags-icon',
  standalone: true,
  imports: [],
  templateUrl: './flags-icon.component.html',
  styleUrl: './flags-icon.component.css',
})
export class FlagsIconComponent implements OnChanges {
  @Input('lang') lang: string = 'fr';
  img_src: string = `https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/flags/4x3/${
    this.lang.toLowerCase() === 'en' ? 'gb' : this.lang.toLowerCase()
  }.svg`;
  constructor() {}
  ngOnChanges(): void {
    this.img_src = `https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/flags/4x3/${
      this.lang.toLowerCase() === 'en' ? 'gb' : this.lang.toLowerCase()
    }.svg`;
  }
}
