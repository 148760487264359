<div class="flag-container">
  <span style="margin-left: 1%">
    <picture>
      <img
        [src]="img_src"
        width="24"
        height="18"
        alt="flag"
        class="flag-icon"
      />
    </picture>
  </span>
</div>
